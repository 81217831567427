import React from "react"
import Layout from "../containers/layout/layout"
import SEO from '../components/seo'
import Header from '../containers/layout/header'
import FaqTitleArea from '../containers/faq/faq-title-area'
import FaqBannerArea from '../containers/faq/faq-banner-area'
import FaqContentArea from '../containers/faq/faq-content-area'
import InstagramWrap from '../containers/global/instagram'
import Footer from '../containers/layout/footer' 

const FaqPage = () => (
  <Layout>
    <SEO 
        title="Faq" 
        description="On this page you can find information about.. "
        keywords={["faq", "faq"]}
    />
    <Header/>
    <div className="main-content">
        <FaqTitleArea/>
        <FaqBannerArea/>
        <FaqContentArea/>
        <InstagramWrap/>
    </div>
    <Footer/>
  </Layout>
)

export default FaqPage
