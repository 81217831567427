import React from 'react'
import { Accordion } from 'react-bootstrap';
import {Container, Row, Col} from 'reactstrap'
import {useStaticQuery, graphql} from 'gatsby'
import Heading from '../../../components/shared/heading'
import {FaqContentWrap, FaqContentTop} from './faq-content-area.stc'

const FaqContentArea = (props) => {
    const faqData = useStaticQuery(graphql `
        query FaqContentQuery {
            faqJson(id: {eq: "faq-page-content"}) {
                top_content {
                    heading
                    content {
                        Q1
                        A1
                        Q2
                        A2
                        Q3
                        A3
                        Q4
                        A4
                        Q5
                        A5
                        Q6
                        A6
                        Q7
                        A7
                        Q8
                        A8
                        Q9
                        A9
                        Q10
                        A10
                        Q11
                        A11
                        Q12
                        A12
                        Q13
                        A13
                        Q14
                        A14
                        Q15
                        A15
                        Q16
                        A16
                        Q17
                        A17
                    }
                }
            }
        }
    `)
    const {top_content} = faqData.faqJson
    const {topHeadingCSS} = props;
    return (
        <FaqContentWrap>
            <Container>
                <Row className="justify-content-center">
                    <Col lg={10} xl={8}>
                        {top_content.heading && <Heading {...topHeadingCSS}>{top_content.heading}</Heading>}
                        {top_content && (
                            <FaqContentTop>
                                <Accordion class="accordion accordion-flush" defaultActiveKey="0" flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header {...topHeadingCSS}>{top_content.content.Q1}</Accordion.Header>
                                    <Accordion.Body>
                                        <div dangerouslySetInnerHTML={{ __html: top_content.content.A1 }} />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header {...topHeadingCSS}>{top_content.content.Q2}</Accordion.Header>
                                    <Accordion.Body>
                                        <div dangerouslySetInnerHTML={{ __html: top_content.content.A2 }} />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header {...topHeadingCSS}>{top_content.content.Q3}</Accordion.Header>
                                    <Accordion.Body>
                                        <div dangerouslySetInnerHTML={{ __html: top_content.content.A3 }} />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header {...topHeadingCSS}>{top_content.content.Q4}</Accordion.Header>
                                    <Accordion.Body>
                                        <div dangerouslySetInnerHTML={{ __html: top_content.content.A4 }} />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header {...topHeadingCSS}>{top_content.content.Q5}</Accordion.Header>
                                    <Accordion.Body>
                                        <div dangerouslySetInnerHTML={{ __html: top_content.content.A5 }} />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header {...topHeadingCSS}>{top_content.content.Q6}</Accordion.Header>
                                    <Accordion.Body>
                                        <div dangerouslySetInnerHTML={{ __html: top_content.content.A6 }} />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header {...topHeadingCSS}>{top_content.content.Q7}</Accordion.Header>
                                    <Accordion.Body>
                                        <div dangerouslySetInnerHTML={{ __html: top_content.content.A7 }} />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header {...topHeadingCSS}>{top_content.content.Q8}</Accordion.Header>
                                    <Accordion.Body>
                                        <div dangerouslySetInnerHTML={{ __html: top_content.content.A8 }} />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="8">
                                    <Accordion.Header {...topHeadingCSS}>{top_content.content.Q9}</Accordion.Header>
                                    <Accordion.Body>
                                        <div dangerouslySetInnerHTML={{ __html: top_content.content.A9 }} />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="9">
                                    <Accordion.Header {...topHeadingCSS}>{top_content.content.Q10}</Accordion.Header>
                                    <Accordion.Body>
                                        <div dangerouslySetInnerHTML={{ __html: top_content.content.A10 }} />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="10">
                                    <Accordion.Header {...topHeadingCSS}>{top_content.content.Q11}</Accordion.Header>
                                    <Accordion.Body>
                                        <div dangerouslySetInnerHTML={{ __html: top_content.content.A11 }} />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="11">
                                    <Accordion.Header {...topHeadingCSS}>{top_content.content.Q12}</Accordion.Header>
                                    <Accordion.Body>
                                        <div dangerouslySetInnerHTML={{ __html: top_content.content.A12 }} />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="12">
                                    <Accordion.Header {...topHeadingCSS}>{top_content.content.Q13}</Accordion.Header>
                                    <Accordion.Body>
                                        <div dangerouslySetInnerHTML={{ __html: top_content.content.A13 }} />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="13">
                                    <Accordion.Header {...topHeadingCSS}>{top_content.content.Q14}</Accordion.Header>
                                    <Accordion.Body>
                                        <div dangerouslySetInnerHTML={{ __html: top_content.content.A14 }} />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="14">
                                    <Accordion.Header {...topHeadingCSS}>{top_content.content.Q15}</Accordion.Header>
                                    <Accordion.Body>
                                        <div dangerouslySetInnerHTML={{ __html: top_content.content.A15 }} />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="15">
                                    <Accordion.Header {...topHeadingCSS}>{top_content.content.Q16}</Accordion.Header>
                                    <Accordion.Body>
                                        <div dangerouslySetInnerHTML={{ __html: top_content.content.A16 }} />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="16">
                                    <Accordion.Header {...topHeadingCSS}>{top_content.content.Q17}</Accordion.Header>
                                    <Accordion.Body>
                                        <div dangerouslySetInnerHTML={{ __html: top_content.content.A17 }} />
                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>
                            </FaqContentTop>
                        )}
                    </Col>
                </Row>
            </Container>
        </FaqContentWrap>
    )
}

FaqContentArea.defaultProps = {
    topHeadingCSS: {
        as: 'h4',
        color: 'secondaryColor',
        lineHeight: 1.375,
        mb: '17px'
    },
    listHeadingCSS: {
        as: 'h5',
        mb: '14px'
    }
}

export default FaqContentArea


  